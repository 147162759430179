import React, { FC } from 'react'
import styles from './CommonBanner.module.scss'
import cn from 'classnames'
import LogoIcon from '../../public/icons/banner-title.svg'
import Tags from '../news/Tags'
import { AuthorSmall } from '../common/AuthorSmall/AuthorSmall'
import Corner from './Corner'
import { Author } from '../../interfaces/Author'
import MaxWidth from '../layout/MaxWidth'
import Image from '../common/Image'

const bannerData = {
  title: 'Обзор Huawei Mate X6',
  text: 'Практически идеальный',
  img: '/images/common-banner-huawei.png',
  imgMobile: '/images/common-banner-huawei-mobile.png',
  href: '/obzor-huawei-mate-x6',
  tags: [{ id: 'review', name: 'Обзор', slug: 'obzor-huawei-mate-x6' }],
  author: {
    name: 'Василий Шкодник',
    slug: 'podogreykin',
    picture: {
      url: 'https://kod.ru/content/images/2024/01/--------------2024-01-18---13.57.48.png',
    },
  },
}

const CommonBanner: FC<{ className?: string }> = ({ className }) => {
  return (
    <Corner>
      <a className={styles.link} href={bannerData.href}></a>
      <Image
        width={1380}
        height={600}
        src={bannerData.img}
        alt={bannerData.title}
        className={styles.bg}
      />
      <Image
        width={640}
        height={800}
        src={bannerData.imgMobile}
        alt={bannerData.title}
        className={cn(styles.bg, styles['bg--mobile'])}
      />

      <MaxWidth className={cn(styles.content, className)}>
        <Tags className={styles.tags} tags={bannerData.tags} />
        <div className={styles.info}>
          <h2 className={cn(styles.title, 'h1')}>{bannerData.title}</h2>
          <p className={cn(styles.text, 'h5')}>{bannerData.text}</p>
        </div>
        <AuthorSmall
          author={bannerData.author as Author}
          className={styles.author}
        />
        <LogoIcon className={cn(styles.mainLogo)} />
      </MaxWidth>
    </Corner>
  )
}

export default CommonBanner
